<template>
    <div>
        <div class="p-md-4 p-3">
            <router-link :to="{name: 'History'}">
                <div id="transactionTitle">Transaction > Deposit > {{ depositID }}</div>
            </router-link>
        </div>

        <div class="table ml-md-4 mr-md-4 ml-2 mr-2">

            <div class="card acc_balance">
                <div class="card-header">
                    <h4 class="card-title">Your Account Information</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Account Number</span>
                            <h4 class="text-primary">{{ userData.account_number }}</h4>
                        </div>

                        <div class="col-12 col-md-6">
                            <span>Routing Number</span>
                            <h4 class="text-primary">{{ userData.routing_number }}</h4>
                        </div>
                    </div>

                    <span>Date</span>
                    <h4 class="text-primary">{{ date }}</h4>

                </div>
            </div>

            <div class="card acc_balance">
                <div class="card-header">
                    <h4 class="card-title">Transaction Details</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Amount Deposited ({{ depositData.currency_fiat }})</span>
                            <h4 class="text-primary">${{ depositData.amount_fiat }}</h4>
                        </div>

                        <div class="col-12 col-md-6">
                            <span>Status</span>
                            <h4 class="text-primary">{{ depositData.verified_at ? "Completed" : "Pending" }}</h4>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Reviewed by</span>
                            <h4 class="text-primary">{{ depositData.reviewed_by }}</h4>
                        </div>
                        <div class="col-12 col-md-6">
                            <div v-if="depositData.rejected_at">
                                <span>Rejected at</span>
                                <h4 class="text-primary">{{ String(new Date(depositData.rejected_at)).split('GMT')[0] }}</h4>
                            </div>

                            <div v-else>
                                <span>Approved by</span>
                                <h4 class="text-primary">{{ String(new Date(depositData.updated_at)).split('GMT')[0] }}</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
    #transactionTitle{
        font-size: 20px;
    }

    .table{
        max-width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .table > div{
        width: 500px;
        background: white;
        margin: 1em;
        box-shadow: 0px 2px 2px -1px rgba(129, 90, 90, 0.3);
    }
</style>

<script>
import api from "@/api";

export default {
    name: 'TransactionDeposit',
     data() {
        return { 
            depositData: "",
            userData: "",
            depositID: "",
        }
    },
    async beforeMount(){
        const id = this.$route.params.id;
        this.depositID = id;
        const deposit = await api.deposit.read(id)
        this.depositData = deposit;

        // Get the account details from his ID
        const account_id = deposit.account_id;

        const userData = await api.account.read(account_id);
        this.userData = userData
    },
    computed: {
        date(){
            let date = new Date(this.depositData.created_at);
            date = String(date);
            return date.split('GMT')[0];
        }
    }
}
</script>