<template>
  <div>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">All Transaction</h4>
              </div>
              <div class="card-body">
                <div class="transaction-table">
                  <Table />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/TransactionTable.vue';

export default {
  components: { Table },
};
</script>

<style lang="scss" scoped>
.sold-thumb,
.buy-thumb {
  color: #FFF;
}

.table {
  color: var(--text);
  tr:not(:first-child) td {
    padding: 10px 15px;
  }
}
</style>
