<template>
    <div class="pb-5">
        <div class="p-md-4 p-3">
            <router-link :to="{name: 'History'}">
                <div id="transactionTitle">Transaction > Trade > {{ tradeID }}</div>
            </router-link>
        </div>

        <div class="table ml-md-4 mr-md-4 ml-2 mr-2">

            <div class="card acc_balance">
                <div class="card-header">
                    <h4 class="card-title">Your Account Information</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Wallet ID</span>
                            
                            <a target="_blank" v-bind:href="'https://sochain.com/address/BTC/' + wallet.address">
                            <h5 class="text-primary">{{ wallet.address }}</h5></a>

                          
                        </div>

                        <div v-if="tradeData.blockchain_id" class="col-12 col-md-6">
                            <span>Blockchain ID</span>
                            <div>
                            <a target="_blank" v-bind:href="'https://sochain.com/tx/BTC/' + tradeData.blockchain_id">                                
                            <small :title="tradeData.blockchain_id" class="text-primary">{{ tradeData.blockchain_id }}</small></a>
                            </div>
                        </div>
                    </div>

                    <span>Date</span>
                    <h4 class="text-primary">{{ date }}</h4>

                </div>
            </div>

            <div class="card acc_balance">
                <div class="card-header">
                    <h4 class="card-title">Transaction Details</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Amount paid ({{ tradeData.currency_fiat }})</span>
                            <h4 class="text-primary">{{ tradeData.amount_fiat }}</h4>
                        </div>

                        <div class="col-12 col-md-6">
                            <span>Amount paid ({{ tradeData.currency_crypto }})</span>
                            <h4 class="text-primary">{{ Number(tradeData.amount_crypto).toFixed(5) }}</h4>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Rate</span>
                            <h4 class="text-primary">{{ Number(tradeData.base_rate).toFixed(2) }}</h4>
                        </div>
                        <div class="col-12 col-md-6">
                            <div v-if="tradeData.rejected_at">
                                <span>Rejected at</span>
                                <h4 class="text-primary">{{ String(new Date(tradeData.rejected_at)).split('GMT')[0] }}</h4>
                            </div>

                            <div v-else>
                                <span>Approved at</span>
                                <h4 class="text-primary">{{ String(new Date(tradeData.sent_at)).split('GMT')[0] }}</h4>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <span>Status</span>
                            <h4 class="text-primary">{{ tradeData.blockchain_id ? "Completed" : "Pending" }}</h4>
                        </div>
                        <div class="col-12 col-md-6">
                            <span>Reviewed by</span>
                            <h4 v-if="tradeData.reviewed_by" class="text-primary">{{ tradeData.reviewed_by }}</h4>
                            <h4 v-else class="text-primary"> Not yet reviewed </h4>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<style>
    #transactionTitle{
        font-size: 18px;
    }

    .table{
        max-width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .table > div{
        width: 500px;
        background: white;
        margin: 1em;
        box-shadow: 0px 2px 2px -1px rgba(129, 90, 90, 0.5);
    }

    .detail {
        font-size: 1.9em;
    }
</style>

<script>
import api from "@/api";

export default {
    name: 'TransactionTrade',
    data() {
        return { 
            tradeData: "",
            userData: "",
            tradeID: "",
            wallet: "",
        }
    },
    async beforeMount(){
        const id = this.$route.params.id;
        this.tradeID = id;
        const trade = await api.trade.read(id)
        this.tradeData = trade;

        // Get the account details from his ID
        const account_id = trade.account_id;
        const userData = await api.account.read(account_id);
        this.userData = userData

        // Get the wallet information

        const walletID = this.tradeData.wallet_id;
        const wallet = await api.wallet.read(walletID);
        this.wallet = wallet;
    },
    computed: {
        date(){
            let date = new Date(this.tradeData.sent_at);
            date = String(date);
            return date.split('GMT')[0];
        }
    }
}
</script>